@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Rubik', serif;
  color: white;
}

body {
  background-color: #0c0f2f;
}

nav {
  background-color: #0c0f2f;
  border-bottom: solid 2px rgba(255, 255, 255, 0.2);
}

.signinCard {
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.25);
}